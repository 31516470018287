import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { SETTINGS_SERVICE_BASE } from '../../../../services/DiExtensions';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { ISettingsServiceBase } from '../../../../services/integrationSettings/IEtlSettingsService';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { IntegrationRefreshService } from '../../../../services/integration-refresh.service';

@Component({
  selector: 'app-source-destination-settings',
  templateUrl: './source-destination-settings.component.html',
  styleUrls: ['./source-destination-settings.component.scss']
})
export class SourceDestinationSettingsComponent implements OnInit, OnDestroy {
   public isLoaded: Observable<boolean>;
  public isDisabled: boolean = false;
  public SourceDataSource: RentaDropdownDataSource;
  public DestinationDataSource: RentaDropdownDataSource;
  public DestinationAccountsDataSource: RentaDropdownDataSource;
  private destroy$: Subject<void> = new Subject<void>();


  constructor(
    @Inject(SETTINGS_SERVICE_BASE) private settingsService: ISettingsServiceBase,
    private integrationRefreshService: IntegrationRefreshService
  ) {
    this.isDisabled = true;
  }

  public ngOnInit(): void {

    this.SourceDataSource = new RentaDropdownDataSource('Source', []);
    this.DestinationDataSource = new RentaDropdownDataSource('Destination Type', []);
    this.DestinationAccountsDataSource = new RentaDropdownDataSource('Destination', []);

    this.isLoaded = this.settingsService.connectToLoading();

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.getIntegrationSourceTokens().subscribe((res: Array<ListItemModel>): void => {
      this.SourceDataSource.refresh(res);
    });

    this.settingsService.getAvailableIntegrationDestinations().subscribe((res: Array<ListItemModel>): void => {
      this.DestinationDataSource.refresh(res);
    });

    this.settingsService.getDestinationTokens().subscribe((res: Array<ListItemModel>): void => {
      this.DestinationAccountsDataSource.refresh(res);
    });

    this.isDisabled = false;

    this.integrationRefreshService.refreshEvent
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.settingsService.getDestinationTokens())
      )
      .subscribe((res: Array<ListItemModel>) => {
        this.DestinationAccountsDataSource.refresh(res);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addSourceAccount(): void {
    this.settingsService.addSourceAccount().subscribe((res: boolean): void => {
      if (!res) {
        return;
      }

      this.isDisabled = true;
      this.settingsService.getIntegrationSourceTokens().subscribe((tokens: Array<ListItemModel>): void => {
        this.isDisabled = false;
        this.SourceDataSource.refresh(SourceDestinationSettingsComponent.UpdateCollection(tokens, this.SourceDataSource.getCollection()));
      });
    });
  }

  public addDestinationAccount(): void {

    console.log( 'SDS comp Click on PLUS addDestinationAccount' )

    this.settingsService.addDestinationAccount().subscribe((res: boolean): void => {

      console.log( ' Click on PLUS addDestinationAccount' )

      /// console.log(' SDS comp this.DestinationAccountsDataSource.getCollection()', this.DestinationAccountsDataSource.getCollection())
        //   console.log(' SDS comp tokens', tokens)

      if (!res) {

        console.log( 'SDS comp Click on PLUS addDestinationAccount !res)' , !res)
        return;
      }

      this.isDisabled = true;
      this.settingsService.getDestinationTokens()
        .pipe(
          tap(v => console.log('SDS comp RES', v))
        )

        .subscribe((tokens: Array<ListItemModel>): void => {


          console.log(' SDS comp this.DestinationAccountsDataSource.getCollection()', this.DestinationAccountsDataSource.getCollection());
          console.log(' SDS comp tokens', tokens);

          this.isDisabled = false;


          this.DestinationAccountsDataSource.refresh(SourceDestinationSettingsComponent.UpdateCollection(tokens, this.DestinationAccountsDataSource.getCollection()));
        });
    });
  }

  public onApplySource(event: ListItemModel): void {
    this.settingsService.setSource(event.id);
  }

  public onApplyDestinationType(event: ListItemModel): void {
    if (!this.settingsService.setDestination(event.id)) {
      return;
    }

    this.settingsService.getDestinationTokens().subscribe((res: Array<ListItemModel>): void => {
      this.DestinationAccountsDataSource.refresh(res);
    });
  }

  public onApplyDestinationToken(event: ListItemModel): void {

    /// BUTTON PLUS
    console.log(' onApplyDestinationToken ');

    this.settingsService.setDestinationToken(event.id);
  }

  public static UpdateCollection(newArr: Array<ListItemModel>, oldArr: Array<ListItemModel>): Array<ListItemModel> {
    if (oldArr.length === 0) {
      return newArr;
    }

    return newArr.map((newElem: ListItemModel): ListItemModel => {
      newElem.selected = true;

      oldArr.map((oldElem: ListItemModel): void => {
        if (newElem.id === oldElem.id) {
          newElem.selected = false;
        }
      });

      return newElem;
    });
  }

  private onError(errors: Array<ErrorMessage>): void {
    const prefix = 'DataSource';
    for (const error of errors) {
      const dataSourceName = error.message + prefix;
      const dataSourceError = error.userMessage;
      if (this.hasOwnProperty(dataSourceName)) {
        this[dataSourceName].setError(dataSourceError);
      }
    }
  }
}
